import React from 'react'
import logo from '../assets/logo.png'

const AuthLayouts = ({ children }) => {
  return (
    <>
      {/* <header className='flex justify-center items-center py-3 h-24 shadow-md bg-transparent'>
        <img 
          src={logo}
          alt='logo'
          width={240}
          height={80}  
        />
      </header> */}

      { children }
    </>
  )
}

export default AuthLayouts
