import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Forgotpassword = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Navigate to the specified URL on component mount
    window.location.href = 'https://certifiedha.org/forgot-password';
  }, [navigate]);

  return (
    <div>
      Redirecting to Forgot Password page...
    </div>
  );
}

export default Forgotpassword;
