import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'
import toast from 'react-hot-toast';
import Avatar from '../components/Avatar';
import { useDispatch } from 'react-redux';
import { setToken } from '../redux/userSlice';

const CheckPasswordPage = () => {
  const [data, setData] = useState({
    password: "",
  })
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!location?.state?.name) {
      navigate('/email')
    }
  }, [])

  const handleOnChange = (e) => {
    const { name, value } = e.target

    setData((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    const URL = `/api/password`
    // const URL = `${process.env.REACT_APP_BACKEND_URL}/api/password`

    try {
      const response = await axios({
        method: 'post',
        url: URL,
        data: {
          userId: location?.state?._id,
          password: data.password
        },
        withCredentials: true
      })

      toast.success(response.data.message)

      if (response.data.success) {
        dispatch(setToken(response?.data?.token))
        localStorage.setItem('token', response?.data?.token)

        setData({
          password: "",
        })
        navigate('/')
      }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }

  return (
    <div className="h-screen flex items-center justify-center bg-gray-100 text-gray-800">
      <div className='bg-white w-full max-w-md rounded-lg overflow-hidden p-6 shadow-xl animate-fade-in mx-auto'>
        
        <div className='w-fit mx-auto mb-4 flex justify-center items-center flex-col'>
          <Avatar
            width={80}
            height={80}
            name={location?.state?.name}
            imageUrl={location?.state?.profile}
          />
          <h2 className='font-semibold text-lg mt-2'>{location?.state?.name}</h2>
        </div>

        <form className='grid gap-6 mt-4' onSubmit={handleSubmit}>
          <div className='flex flex-col gap-2'>
            <label htmlFor='password' className="text-sm font-medium">Password:</label>
            <input
              type='password'
              id='password'
              name='password'
              placeholder='Enter your password'
              className='bg-gray-200 px-3 py-2 rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#2460E8] transition-transform transform hover:scale-105 duration-300'
              value={data.password}
              onChange={handleOnChange}
              required
            />
          </div>

          <button
            className='bg-[#2460E8] hover:bg-blue-700 text-lg px-4 py-2 rounded-lg font-bold text-white leading-relaxed tracking-wide transition-transform transform hover:scale-105 duration-300'
          >
            Login
          </button>
        </form>

        <p className='my-4 text-center'>
          <Link to={"/forgot-password"} className='hover:text-[#2460E8] font-semibold'>Forgot password?</Link>
        </p>
      </div>
    </div>
  )
}

export default CheckPasswordPage;
