import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import toast from 'react-hot-toast';

const CheckEmailPage = () => {
  const [data, setData] = useState({
    email: "",
  })
  const navigate = useNavigate()

  const handleOnChange = (e) => {
    const { name, value } = e.target

    setData((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    // const URL = `${process.env.REACT_APP_BACKEND_URL}/api/email`
    const URL = `/api/email`

    try {
      const response = await axios.post(URL, data)

      toast.success(response.data.message)

      if (response.data.success) {
        setData({
          email: "",
        })
        navigate('/password', {
          state: response?.data?.data
        })
      }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }

  return (
    <div className="h-screen flex items-center justify-center bg-gray-100 text-gray-800">
      <div className='bg-white w-full max-w-md rounded-lg overflow-hidden p-6 shadow-xl animate-fade-in mx-auto'>
        <h3 className="text-xl font-semibold text-center">Use your CHA Dashboard Credentials</h3>

        <form className='grid gap-6 mt-6' onSubmit={handleSubmit}>
          <div className='flex flex-col gap-1'>
            <label htmlFor='email' className="text-sm font-medium">Email:</label>
            <input
              type='email'
              id='email'
              name='email'
              placeholder='Enter your email'
              className='bg-gray-200 px-3 py-2 rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#2460E8] transition-transform transform hover:scale-105 duration-300'
              value={data.email}
              onChange={handleOnChange}
              required
            />
          </div>

          <button
            className='bg-[#2460E8] hover:bg-blue-700 text-lg px-4 py-2 rounded-lg font-bold text-white leading-relaxed tracking-wide transition-transform transform hover:scale-105 duration-300'
          >
            Let's Go
          </button>
        </form>
      </div>
    </div>
  )
}

export default CheckEmailPage;
